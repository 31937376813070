import React from "react";
import {StyledModal} from "./styles/Modal.styled";
import {injected, wcConnector} from "../connectors";
import styled from "styled-components";
import {Button} from "./styles/Button.styled";

const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
`


const XButton = styled.button`
    background-color: Transparent;
    border: none;
    color: white;
    font-size: 18px;
    cursor: pointer;
`

const Flex = styled.div`
    display: flex;
    justify-content: right;
`

export function Modal({setShowModal, context}) {
    console.log("in Modal");
    return (
        <>
            <StyledModal>
                <Flex>
                    <XButton
                        onClick={() => {
                            setShowModal(false);
                            console.log("PRESSED!");
                        }}
                    >
                        X
                    </XButton>
                </Flex>

                <div>
                    <h3>Connect a Wallet</h3>
                </div>
                <ButtonWrapper>
                    <Button
                        onClick={() => {
                            console.log("Connecting to injected");
                            context.activate(injected);
                        }}
                    >
                        MetaMask
                    </Button>
                    <Button
                        onClick={() => {
                            console.log("Connecting to WalletConnect");
                            context.activate(wcConnector);
                        }}
                    >
                        WalletConnect
                    </Button>
                </ButtonWrapper>
            </StyledModal>
        </>
    );
}
