import {StyledCard} from "./styles/Card.styled";
import {Button} from "./styles/Button.styled";
import {Flex} from "./styles/Flex.styled";
import {Modal} from "./Modal";
import ReactAudioPlayer from 'react-audio-player';
import React from "react";
import styled from 'styled-components';
import brains from "../images/brains.svg";
import CodeRed from "../music/song.mp3"
import Growl from "../music/growl.wav"
import { useState } from "react";
import { getProof, isVip, isPre } from "../merkle/merkletree";

export default function Card({context, connected, contract, blockNumber, account}) {
    // FOR DEV TO GET MERKLE ROOTS FOR CONTRACT ==================
    /*
    console.log("Card");
    const merkleRoot_Reg = new MerkleTree(presaleRegList_hashed, keccak256, {
        sort: true
    });
    const merkleRoot_Vip = new MerkleTree(presaleVipList_hashed, keccak256, {
        sort: true
    });
    console.log("merkleRoot_Reg", merkleRoot_Reg.getHexRoot());
    console.log("merkleRoot_Vip", merkleRoot_Vip.getHexRoot());
    */
    // ===========================================================
    const [showModal, setShowModal] = React.useState(false);
    const [name, setName] = React.useState('');
    const [mintState, setMintState] = React.useState(0);
    const [maxMints, setMaxMints] = React.useState(0);
    const [curMinted, setCurMinted] = React.useState(0);
    const [accountMints, setAccountMints] = React.useState(0);
    const [maxPublicMintsPerWallet, setMaxPublicMintsPerWallet] = React.useState(0);
    const [maxVipMintsPerWallet, setMaxVipMintsPerWallet] = React.useState(0);
    const [maxPreMintsPerWallet, setMaxPreMintsPerWallet] = React.useState(0);
    const [vip, setVip] = React.useState(false);
    const [brainsToMint, setBrainsToMint] = React.useState(0);
    const [didMint, setDidMint] = React.useState('');
    const [audioPlayer, setAudioPlayer] = React.useState({});
    const [growl, setGrowl] = React.useState({});

    React.useEffect(() => {
        setVip(isVip(account))
        if (context.active) {
            contract.methods
                .currentHorde()
                .call()
                .then(mint_state => {
                    setMintState(mint_state);
                });

            contract.methods
                .MAX_HORDE()
                .call()
                .then(maxMints => {
                    setMaxMints(maxMints);
                });

            // contract.methods
            //     .TotalMinted()
            //     .call()
            //     .then(cur_punks => {
            //         setCurPunks(cur_punks);
            //     });

            contract.methods
                .getMintCountFromAddress(account)
                .call()
                .then(mints => {
                    setAccountMints(mints == null ? 0 : mints);
                });

            contract.methods
                .NIGHTWALKER_ALLOCATED()
                .call()
                .then(max_mints => {
                    setMaxVipMintsPerWallet(max_mints);
                });
            // Overwrite VIP if you're mastermind
            contract.methods
                .MASTERMINDS_ALLOCATED()
                .call()
                .then(max_mints => {
                    contract.methods.isMastermind(account).call().then(res => {
                        if(res) { 
                            setMaxVipMintsPerWallet(max_mints)
                            setVip(true)
                        }
                    })
                });

            contract.methods
                .PUKER_ALLOCATED()
                .call()
                .then(max_mints => {
                    setMaxPreMintsPerWallet(max_mints);
                });
            contract.methods
                .ROAMER_ALLOCATED()
                .call()
                .then(max_mints => {
                    setMaxPublicMintsPerWallet(max_mints);
                });

            contract.methods
                .name()
                .call()
                .then(name => {
                    setName(name);
                });
        }
    }, [contract, blockNumber]);

    const contractParams = {
        mintState: mintState,
        maxMints: maxMints,
        curMinted: curMinted,
        accountMints: accountMints,
        maxMintsPerWallet: maxPublicMintsPerWallet,
        maxPreMintsPerWallet: maxPreMintsPerWallet,
        maxVipMintsPerWallet: maxVipMintsPerWallet
    };

    const Subheader = styled.div`
        color: white;
        font-family: Work Sans;
        font-size: 14px;
        text-transform: uppercase;
    `

    return (
        <StyledCard>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', height: '100px' }}>
                <Subheader>Feed the hunger. </Subheader>
            </div>
            <img style={{ paddingTop: '1em', marginTop: '-7em' }} src={brains} alt='' width={window.innerWidth > 720 ? '300px' : '250px'} height='auto' />
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center'}}>
                <Subheader style={{ paddingBottom: '4em' }}>No Discord. No roadmap. No utilities.</Subheader>
                <div style={{ maxWidth: '500px', fontWeight: '600', fontSize: '35px' }}>
                    <div style={{ textTransform: 'uppercase', color: '#FF0000' }}>666 Undead nfts</div>
                    <div style={{ textTransform: 'uppercase' }}>Free Mint collection</div>
                    <div style={{ textTransform: 'uppercase', fontSize: '12px', paddingTop: '1em', color: '#FF0000' }}>Halloween 2022 Edition</div>
                </div>
                <div>
                    {connected
                        ? ConnectedCard(
                            context,
                            contract,
                            blockNumber,
                            contractParams
                        )
                        : ConnectWalletCard(context, showModal, setShowModal)}
                </div>
                <span style={{ opacity: '0.5' }}>
                </span>
            </div>
            <ReactAudioPlayer
                src={CodeRed}
                autoPlay
                ref={(element) => { setAudioPlayer(element) }}
                controlsList="nodownload"
            />
            <ReactAudioPlayer
                src={Growl}
                autoPlay
                volume={0.15}
                ref={(element) => { setGrowl(element) }}
                controlsList="nodownload"
            />
        </StyledCard>
    );

    function ConnectWalletCard(context, showModal, setShowModal) {
        return (
            <div style={{ paddingTop: '4em' }}>
                {showModal ? (
                    <Modal setShowModal={setShowModal} context={context} />
                ) : (
                    <>
                        <Button
                            type="button"
                            onClick={() => {
                                setShowModal(true);
                                audioPlayer.audioEl.current.play()
                                growl.audioEl.current.play()
                            }}
                        >
                            CONNECT THAT SHIT
                        </Button>
                    </>
                )}
            </div>
        );
    }
    
    function ConnectedCard(context, contract, blockNumber, contractParams) {
        const FlexRight = styled.div`
            display: flex;
            justify-content: right;
        `
        const Padding = styled.div`
            padding: 1em;
        `
        return (
            <>  {didMint === '' ? (
                <Padding>
                    {contractParams.mintState === "0"
                    ? MintNotOpenCard()
                    : contractParams.mintState === "1"
                    ? VipMintOpenCard(contractParams, context, contract)
                    : contractParams.mintState === "2"
                    ? PreMintOpenCard(contractParams, context, contract)
                    : contractParams.mintState === "3"
                    ? PublicOpenCard(contractParams, context, contract)
                    : contractParams.mintState === "4"
                        ? MintingDoneCard()
                        : LoadingState()}
                </Padding>
            ) : didMint === 'error' ? (
                    <Padding>
                        {LoadingState()}
                    </Padding>

                ) : didMint === 'tx sent!' ? (
                    <Padding>
                        {TxSent()}
                    </Padding>
                ) : (
                        <Padding>
                            {MintingSuccessful()}
                        </Padding>
                ) }
  
    
            </>
        );
    }
    
    function MintNotOpenCard() {
        return (
            <>
                <h3>Minting has not yet started.</h3>
                <h5>Please check back later!</h5>
            </>
        );
    }

    function preMintSale(_qty, context, contract) {

        // Pre Mint Logic here
        // check if address in whitelist
        const proof = getProof(account, true)

        contract.methods
            .willPukeForBRAINS(_qty.toString(), proof)
            .send({from: account})
            .on("transactionHash", hash => {
                setDidMint('tx sent!')
            })
            .on("error", function(error, receipt) {
                setDidMint('error')
            });
    }
    
    function vipMintSale(_qty, context, contract) {
        const proof = getProof(account, false)
        contract.methods
            .nightwalkerHarvest(_qty.toString(), proof)
            .send({from: account})
            .on("transactionHash", hash => {
                setDidMint('tx sent!')
            })
            .on("error", function(error, receipt) {
                setDidMint('error')
            });
    }

    function VipMintOpenCard(contractParams, context, contract) {
    
        const incPunks = (e) => {
            e.preventDefault();
            if(brainsToMint < maxVipMintsPerWallet) setBrainsToMint(brainsToMint + 1);
        }
    
        const decPunks = (e) => {
            e.preventDefault();
            if(brainsToMint > 0) setBrainsToMint(brainsToMint - 1);
        }
    
        const mint = () => {
            const qty = brainsToMint;
            vipMintSale(qty, context, contract, contractParams);
        }


        const disabled = brainsToMint === 0 || !vip;
    
        return (
            <>
                <div>
                    <form
                        id="publicForm"
                    >
                        <div style={{ padding: '1em', display: 'flex', justifyContent: 'space-evenly', flexDirection: 'column', gap: '8px' }}>
                            <div style={{ color: 'pink' }}>VIP MINT</div>
                            {!vip ? <div>You are not on the VIP list.</div> : 
                                <div style={{ width: '100%' }}>
                                    FREE MINT
                                </div>
                            }
                            <div style={{ width: '100%', display: 'flex' }}>
                                <button onClick={(e) => decPunks(e)} >-</button>
                                <div style={{ width: '100%', background: 'white', color: 'black' }}>{brainsToMint}</div>
                                <button onClick={(e) => incPunks(e)}>+</button>
                            </div>
                            { accountMints > 0 && <div>You have minted { accountMints } Brains (Limit: { maxVipMintsPerWallet }) </div>}
                        </div>
                        <Button disabled={disabled} onClick={mint} type="button">MINT</Button>
                    </form>
                </div>
            </>
        );
    }
    
    function PreMintOpenCard(contractParams, context, contract) {
    
        const incPunks = (e) => {
            e.preventDefault();
            if(brainsToMint < maxPreMintsPerWallet) setBrainsToMint(brainsToMint + 1);
        }
    
        const decPunks = (e) => {
            e.preventDefault();
            if(brainsToMint > 0) setBrainsToMint(brainsToMint - 1);
        }
    
        const mint = () => {
            const qty = brainsToMint;
            preMintSale(qty, context, contract, contractParams);
        }


        const disabled = brainsToMint === 0 || !isPre(account);
    
        return (
            <>
                <div>
                    <form
                        id="publicForm"
                    >
                        <div style={{ padding: '1em', display: 'flex', justifyContent: 'space-evenly', flexDirection: 'column', gap: '8px' }}>
                            <div style={{ color: 'pink' }}>PREMINT</div>
                            {!isPre(account) ? <div>You are not on PREMINT list.</div> : 
                                <div style={{ width: '100%' }}>
                                    FREE MINT
                                </div>
                            }
                            <div style={{ width: '100%', display: 'flex' }}>
                                <button onClick={(e) => decPunks(e)} >-</button>
                                <div style={{ width: '100%', background: 'white', color: 'black' }}>{brainsToMint}</div>
                                <button onClick={(e) => incPunks(e)}>+</button>
                            </div>
                            { accountMints > 0 && <div>You have minted { accountMints } Brains (Limit: { maxPreMintsPerWallet }) </div>}
                        </div>
                        <Button disabled={disabled} onClick={mint} type="button">MINT</Button>
                    </form>
                </div>
            </>
        );
    }
    
    function mintPublicSale(_qty, context, contract, contractParams) {
        contract.methods
            .roamerMint(_qty.toString())
            .send({from: account})
            .on("transactionHash", hash => {
                setDidMint('tx sent!')
            })
            .on("error", function(error, receipt) {
                setDidMint('error')
            });
    }
    
    function PublicOpenCard(contractParams, context, contract) {
    
        const incPunks = (e) => {
            e.preventDefault();
            if(brainsToMint < maxPublicMintsPerWallet) setBrainsToMint(brainsToMint + 1);
        }
    
        const decPunks = (e) => {
            e.preventDefault();
            if(brainsToMint > 0) setBrainsToMint(brainsToMint - 1);
        }
    
        const mint = () => {
            const qty = brainsToMint;
            mintPublicSale(qty, context, contract, contractParams);
        }


        const disabled = brainsToMint === 0;
    
        return (
            <>
                <div>
                    <form
                        id="publicForm"
                    >
                        <div style={{ padding: '1em', display: 'flex', justifyContent: 'space-evenly', flexDirection: 'column', gap: '8px' }}>
                            <div style={{ color: 'pink' }}>PUBLIC MINT</div>
                            <div style={{ width: '100%' }}>
                                FREE MINT
                            </div>
                            <div style={{ width: '100%', display: 'flex' }}>
                                <button onClick={(e) => decPunks(e)} >-</button>
                                <div style={{ width: '100%', background: 'white', color: 'black' }}>{brainsToMint}</div>
                                <button onClick={(e) => incPunks(e)}>+</button>
                            </div>
                            { accountMints > 0 && <div>You have minted { accountMints } Brains (Limit: { maxPublicMintsPerWallet }) </div>}
                        </div>
                        <Button disabled={disabled} onClick={mint} type="button">MINT</Button>
                    </form>
                </div>
            </>
        );
    }

    // ToDo: make cool lol
    function MintingDoneCard() {
        return (
            <>
                <h3>This project is sold out!</h3>
                <h5>Check out the collection on OpenSea.</h5>
            </>
        );
    }

    function MintingSuccessful() {
        return (
            <>
                <h3>Congratulations!</h3>
                <h5>Mint was Successful</h5>
            </>
        );
    }

    function LoadingState() {
        return (
            <>
                <h3>Loading...</h3>
            </>
        );
    }

    function TxSent() {
        return (
            <>
                <h3>Transaction Sent!</h3>
            </>
        );
    }
    
}

