import styled from "styled-components";

export const Flex = styled.div`
    margin: 0 30px;
    display: flex;
    align-items: center;
    justify-content: ${props=>props.jc || "center"};
    h5{
        border: solid rgba(255, 255, 255, .2);
        padding: 5px;
    }
    @media (max-width: ${({theme}) => theme.mobile}) {
        flex-direction: column;
    }
`;
