import {createGlobalStyle} from "styled-components";
import bg from "../../../src/images/background.svg"

const GlobalStyles = createGlobalStyle`
    @keyframes pulse {
        0% {
            transform: scale(1.05);
        }

        70% {
            transform: scale(1);
        }

        100% {
            transform: scale(1.05);
        }
    }
    html{
        margin: 0;
        height: 100%;
        padding: 2em;
    }
    ::-webkit-scrollbar {
        width: 0px;
        background: transparent; /* make scrollbar transparent */
    }

    body{
        overflow: scroll; 
        background: url(${bg});
        background-size: 100%;
        animation: pulse 10s infinite;
        color: white;
        font-family: Work Sans, sans-serif;
        font-size: 1.15em;
        margin: 0;
        height: 100%;
    }
    
    p {
        opacity: 0.6;
        line-height: 1.5;
    }
    img {
        max-width: 100%;
    }
`

export default GlobalStyles;
