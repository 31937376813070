import styled, {ThemeProvider} from "styled-components";
import GlobalStyles from "./components/styles/Global";
import Card from "./components/Card";
import {Container} from "./components/styles/Container.styled";
import { CardWrapper } from "./components/styles/CardWrapper.styled";
import {Web3ReactProvider, useWeb3React} from "@web3-react/core";
import {Web3Provider} from "@ethersproject/providers";

import React from "react";
import ContractABI from './abis/Brains.json';
const Web3 = require('web3');

const contractAddress = "0x4C8C9B92911E9C6021a215078fDE0C83E1c27581";

const theme = {
    colors: {
        header: "transparent",
        body: "#000",
        footer: "#777"
    },
    mobile: "768px"
};


function App() {
    // import context to use web3
    const context = useWeb3React();
    const {
        connector,
        library,
        chainId,
        account,
        activate,
        deactivate,
        active,
        error
    } = context;

    // set up block listener
    const [blockNumber, setBlockNumber] = React.useState();
    React.useEffect(() => {
        if (library) {
            let stale = false;
            library
            .getBlockNumber()
            .then(blockNumber => {
                if (!stale) {
                    setBlockNumber(blockNumber);
                }
            })
            .catch(() => {
                if (!stale) {
                    setBlockNumber(null);
                }
            });

            const updateBlockNumber = blockNumber => {
                setBlockNumber(blockNumber);
            };
            library.on("block", updateBlockNumber);

            return () => {
                library.removeListener("block", updateBlockNumber);
                stale = true;
                setBlockNumber(undefined);
            };
        }
    }, [library, chainId]);


    // set contract and connected status
    const [connected, setConnected] = React.useState(false);
    const [contract, setContract] = React.useState();
    React.useEffect(() => {
        console.log("App: account", account);
        console.log("ChainId", chainId)
        if (account === undefined) {
            setConnected(false);
            window.web3=null
        } else {
            setConnected(true);
            window.web3 = new Web3(window.ethereum);
            setContract(new window.web3.eth.Contract(ContractABI.abi, contractAddress))
        }
    }, [library, account, chainId]);


    return (
        <ThemeProvider theme={theme}>
            <GlobalStyles/>
            <Container>
            <div style={{  paddingTop: '1em', width: '100%', display: 'flex', justifyContent: 'center'}}>
                <CardWrapper>
                    <Card
                        context={context}
                        connected={connected}
                        contract={contract}
                        blockNumber={blockNumber}
                        account={account}
                    />
                </CardWrapper>
            </div>
            </Container>
        </ThemeProvider>
    );
}


// necessary for web3 enabled app
function getLibrary(provider) {
    const library = new Web3Provider(provider);
    library.pollingInterval = 12000;
    return library;
}

export default function() {
    return (
        <Web3ReactProvider getLibrary={getLibrary}>
            <App />
        </Web3ReactProvider>
    );
}
