import styled from "styled-components";

export const Button = styled.button`
    border-radius: 8px;
    border: none;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    font-size: 18px;
    font-weight: 700;
    font-family: Work Sans;
    padding: 15px 30px;
    background-color: ${props => props.bg || "#FF0000"};
    color: ${props => props.color || "black"};
    margin-top: ${props => props.mt || "20"}px;

    &:hover {
        opacity: 0.9;
        transform: scale(0.98);
    }

    &:disabled {
        opacity: 0.2;
    }
`;
