import styled from "styled-components";

export const StyledCard = styled.div`
    text-align: center;
    padding: 1em;
    & > div{
        width:100%;
        margin: 1em auto;
        border-radius: 8px;
        
    }
`;
