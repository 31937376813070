const { MerkleTree } = require('merkletreejs');
const keccak256 = require('keccak256');

const vipHashedWhiteList = [
  "b53112e0a23ddd10f66ea643754302c6a54ebc3ae6021f4c7f717cac5f392e6d",
  "3d8c529f68d79c21736dc12d6a2f55ee167b9ee2330d3860fd6e5226b384102b",
  "47e8fcaf397c3bcff5ed9936335d2507b07b279041dbe9ff7c4117159090cff5",
  "cb9bbe38216a7286c19583e6cae6a9ef59778288ca0be529c06a884acde22e18",
  "05a211602e2a6b7b2a16b0d56d006e462bb2b70e1b4f673ce788d1e3ca25dbf1",
  "e7011b29b633106435cbe7f1a197c92e024a42df4db287dbc994eba0abb12727",
  "7752754fbdf17e18269f0682987fc873e3b3ac46e1aefb051be256d82086a652",
  "1e071da50ec8b86a4ee3240cd5b80036e11bb01b647d32f21ad766613ce81254",
  "526dd0821cbd870097d0999126b9fdf48e391e16f2e876b0029e064e59c7349d",
  "9624b39c2aaf17c40bc90da71b345eead0fd00eaa20215091743450ca5b69feb",
  "178ee592a3ac1ff790e5aecfc4e26f90694ce60d2489e1cae9fe4989dabed914",
  "934ea1e16e8c1e95ccbf74ecfd7e8501634b7df5b456a7625f57472a64770bed",
  "af714afa6e3de6b5d0ee88035faa510a0f5d48b67aada937eebe46bfa58a79e4",
  "4e4cb039a77114598ea9ec7031fbb06bc1bdb7a3aad510b8bdf6d698b69daa22",
  "c8582ccc21df462992a23c374f4cfe5b369c48d606eeb0552ed989b1831489e9",
  "7d9adaa4786ae6af01159eb79a09450fff72c3add6a30d4ef5baeaffff011600",
  "5983b491d9d161b4cb200031e0d86df6bcaa4acabbf3b9a9290b30036f753b32",
  "c284deb9c97c8b79bd1d3cac636564947291bc09bfa5c7fd7f2b21588961a84c",
  "e0233e208c283554ca3f24e3c0f0593dc21689baa92c09a34dfd624182bbdd3c",
  "aaf2ed708b4fb0eb971446443d57304bfb1ec5eac9086489442a56998cde756a",
  "e68ea600e8995d975148293995f3a70a98180659f92ebec6f3c6bb5b72e37399",
  "e68ea600e8995d975148293995f3a70a98180659f92ebec6f3c6bb5b72e37399",
  "27169508921f6d62829f8f39e2511c702d1cd8639b0f6c87a0f720679095927d",
  "6a9bc9fba629d6f8daaa9edb451f78f544299b0a286b0806b0a391b994f227e7",
  "3f7f8a30b8ac9792c63872ec89d25d0a96659e9799d221712fe0c8cc5c1fa27c",
  "0ac73c5bbc527e4e9ad9feec3dd952e8b73040813b495cd9503cb2e7f678a239",
  "29268bea700215b174fc3546fa3d54c96819fc29626582b26a46871fe8267f90",
  "157d07db1fc81fd27333aa99c8d689e95c8001118fce47b2ee9eff3c2f48ff60",
  "a5391c40f5ca7bb410aa218cafadc5fca4e95e2b5dfd47ad198b521cf785d872",
  "87d7f3f194eb5f550ab60cfdaf3bb65302b7b38e6010cfbec375e2529797ade7",
  "6de7ce17b8643ffdef79a884f8afec3040990932e83c3de0fce2ab0a8eff06ff",
  "29b7abd7f501829f5b730ce21f1c6e8979eec99e46a63da6dad6c26d76baf2dd",
  "aa1a6d0d73d6743998aa5c8f7e11bd91f2eea93771f0d4c930e4f62e9df093d8",
  "9a8ad8c1745d6bc8e244a9b3ae99e7f173dd55efa1b9b6e44f6be89925063685",
  "508bc53b797e0ffcfa6ffa076fd938fa2e4d3e8fb2d1b404a35f75c266972acd",
  "596cdd095e784603ee7017750de66287e31f75ad453f2d2f4e5446890587361a",
  "a8f6fa850c4a5139790ed89138a7f1fb194bca3e2672a6ebd413ff409f6f634f",
  "865f8331cb90a912322fd4384af3f32f5f0d057c606270c36130a273a60f1aa5",
  "7e48acce08a5a6c6a98b333172032d6b3220b75d6452613f1403cf68d660154d",
  "cd5dfffe75d52c17681198dbb870b000d171cd090f6622e76d7eac1cf74208fc",
  "09da6b2f392382c57ef1c403c2bc412c7f102ba0ef3ad1fa66e3f53094138cb1",
  "52e7160b824d5df54f1d340b0374f99ea9ccd5e1cab752d2486ebd2e4f211354",
  "71d652282de96a14da3dbee8017ff4c41e32a7c38f54ad2da0a2fc398d55e884",
  "f559ecf2d407b0bc6f75834edc10894d194961cedc478ef414c2c93ef35567b0",
  "07792faaef8c8c623f7c935c2be7e0467da7fa52ea41f1594b1841e727fa2a28",
  "ce2b2258751491a8ab54820790e1cba48bf1b8279858dfae0c856c194bd241ed",
  "ccfff18c30127b8c94453dd453a9de8f95fcafdbb533193e0a414798a8bc9ec2",
  "988e8900452b04cd6dbdfd5cc7d51ac4f1a3152d8c6cdfbcefa452dd41eaafb8",
  "4d724d90a5bd5cb521896f2c2de25944e2e2240024c9b9c63ec22bba3ccfa0cc",
  "658ed731ef44d5f10888ab782d4162a416fb13cb0b25c91eaab2a248a9ad0946",
  "54cfdb7cbd3ac3c22fa241da10b3d781b79152c5d65cf7338661d814ec03e98d",
  "24a19f0262a3f83e0d7299734a9d8d36342e5b6bfa94732b85198d773fb5cf5a",
  "3994e1134e5cf2a5628edcb7412cadb2ac10e922536e4b765759328dc92760c0",
  "2b539485af05687bd1b4a0dfa4005e8b1620bb529ed570096f2e9a01c96c1558",
  "2ff47198ed7edd12f630c24b95aa6fa6f5127229837104f99eb04ed6d20b427c",
  "203997b2bb0a9380862130060e42092a2ca29a15ef1318a7b1235668b58239fb",
  "ef71db7aafb93401f33803450c182affc86f1fd16f2652816e3d28b5b2211063",
  "f80b105a3bc7ef2547a3ece6aaef28844f2be1fba281f40e7c13a1629fe0a3de",
  "9fd8672762d5e44d3d89dbf5bff5dfbfb75e604b0bb9b5f0a9f5899e61f9e561",
  "09da6b2f392382c57ef1c403c2bc412c7f102ba0ef3ad1fa66e3f53094138cb1",
  "7bb77f16b0ba0079821fdaef09289d0e033d40490d8895c049256a45fd9917c7",
  "7af65a26e273f0370b41fb806ee22fda9e6aa29f9e5d8b752b2d038b07573aa5",
  "2ddae5e94397fce915a286710b491906edbbf196b7b596501970d9a47785a8a0",
  "738bcc0e85cc96e2e27aceec06115de150f299366842bdf84d5b360398faf2f5",
  "17742480fd4e70bb5c53f41c61487df43fc8d7caf68b50dc7377d1bf7f9d7bab",
  "e79b09a141ac3e905799a009fee5b0220fc5c868b6f25ca572f9d4ccc1b916db",
  "0553a78964fb5fd0bcab3c718ce1ac8af5afa6f472c81edde3c99d061c4e5007",
  "19aa18d064888b0bc4a6327180283b8b2298f03d7e0ef7fcc1a75a280f8be951",
  "29aae682b63b7a9588caa9787ff2241b6072890cdc1ac05f8a4e8d394736d165",
  "76e41503fd531e5eb42c8998a8800402710c9526652fcb5a3742e3485227557f",
  "eab2bc5b9451015e4e088fb57800d1cd17bc335af7fbdb02562dcb6e55ad4bde",
  "308d5f3074a752e9f3041d76721d7546f90add393a4476fcc1a2f5c745a830ee",
  "11c9e5d66ed2b634e90b5a81dc5c7df94558c05c8164be0b4c50e036545b707a",
  "2a2db8b3b5b747c5f7c2c1ab9f184ad51b7a5785e7e8df83109bcad226dddb6c",
  "4624ac0da2e0d84dd7fa53802e6ec438c7de6fe7fd1f4168dc79a63e4c53d6ea",
  "c34e57cbb541130b72c9c6bd7757fe5a92ee6e6900fc9d25d42796f1a674cff9",
  "ba34591465c41db272190cb85c6580dfe238dbda47094ff7085125dad9ddce30",
  "f135d4c004d331b2e7380bc0310a3822776edf2e37f7205eb52a06f83cfba389",
  "ce827a2918db404cd50cdb836b41caef0d90e9f252f653009ce1d8b80c8f88a6",
  "597086cb2ab9407ab7dc407f3bd5d64e5af1998ef6433a701e819478c337e211",
  "2a898a3eefe0124bcc29e3e889a52ffd84e193b0681d938dd7e0d3bbe190be34",
  "ae16b651ca4d54cb2698c12978ce5eefe6c9c82b3c8a6c21de73d4397e553e81",
  "20fd182f0ede1408e8ff81c67b56c04577a67554a76ef0ceccac94d6871766de",
  "a56daa899e8635be697e5d3e40090ea0e7c7c14f4ab9c85735ed771515e18705",
  "dab3e98a330de43f729fef9f86a145297a41ed8e7805789467bc8566ada6ee4a",
  "5a46c805a10522251f3f99368f1a0c96e876b3bcb91e4abb45f244203cbe882d",
  "34805d0c9e80a9bb6fcff238d46af7f6237c18f33676beac599b01458fee8a5e",
  "f87921f1ae1e1b2e700d0f7fe0b5ed9c5a7ff066d68a0406c8790987681f9e51",
  "dcc4f190c329080fe9512c66e75039e733c2301959a8332375bc71d166063573",
  "31940ddb5c5fb86c86d73d291f464ed829f2c6cf0f0fcf59ec8f9cb0aa7a6e7d",
  "45be30ede23585b434f8d6855ad950bab32c471a7360143bebb363c542f4423b",
  "77c5065d2f577ac6860574abf968193d98e390271e462ef6e9cb2b24004850a4",
  "8d023a229585f487cb5a3547b671b15d13191547c79731deb40325680cca0957",
  "30e92c7ff59440eb040d869b4d6e011951a3ef9197e7284cf74c8d88ce650225",
  "5d7d6fd50ec9cc9d3a2290a96840aedcd14b7b4ddf7f624c19214ebac70ac67f",
  "53d7de2e89570b1b2b0fb4d3520585a636a2e9c0ad8f3e6e218d48ef703c95c6",
  "57859aa243ad6de340987abddc283bf9c98bf35e99151e6e2ee5407cb178d5fc",
  "67afdbd1a3bcde24890eebc921044ca17fbe347d62cd9da5dd85ebc755c3ac05",
  "7bfbd44db0cbf4933f602302913c62743d3eef9b75c26976e1bc614c4b41ffbd",
  "2ee7ee6cdd27f3742c360abfd04fa2102e1485a239a85ffc8cd881df3f4946d5",
  "4d2c93580fbcb1a2de17b55385eeec00f3ebb60ee9f686944ee10882b6ebbac6",
  "bb8a5b84585288699003f86fc9394a426f3e7d622536ec5f24835ebd3682de57",
  "2ebc5032a6d2b89e3a966f38f4559fab1171b2f68f7a9e410b2701fbd8768a13",
  "336fea013db55393fb4e18d0add81471059d49cabab4e8fbfd7d283306a5a217",
  "c5746434c39f44db205d4c6ba6b801f006ffd6b8be6989c488fbc4eb4f706ee3",
  "7fb89c0a149b41d851a3a5468c7f1907a4b493f72dfd59993fa05e1d88626be0",
  "1182f9943fbbd03996da8b492737b52d4fcae21ce1a30e4b20cd3ada430638bf",
  "aaba3c693c395239e3a358f8e20c12d4feb550a25fdba9a835b03292c93f439d",
  "44b975f2a3291d28e0f3fb42b2a7dfa0518ab7b4b17a04d9939e385d185137b4",
  "c59c54f424b915c5c1361a854ab9239d98a88bb3a1445e44001a873bcb292cfc",
  "0027df7b01c1b8153042c0e313e8f314b5921a3936cd2c773896ceecfc302ffa",
  "f7ac78810937f1dd829886603b51780ec1bb030a9d8ce4dce03c6efa4ae8ef0f",
  "efcd0a97237f4edff6a4b8f04f19294c6a5e253c3f644b6842de4236bda31e32",
  "f161edb3ad86be5c00b4a9efb26fdb98c9619f5a577c1334543da1ce2293f836",
  "2ef236b3b7897ce740463af67dcd1e35fd3febba436571f62a9e76cdf4a78e13",
  "717c74be84c00e0c7d3d317a064a43b65f5090e4e0ca5dbaeb53a33b1e74558b",
  "56df64d7a24b73da74bd9c2b0ee353e4ea2c31948c42b3a40038e7967e23f52d",
  "77547c2f330f8561d35f053011639c88e61a70e9e5694f9905b2a1a9ea11a8d6",
  "df871d6f24ace515ed96018c26e9afd9a76a89d8b19b175a4a60c0708acfc883",
  "32370bae0b1c75b7c9b1470c051f348b99048f87f38986f2aa3173a8ec8fbfbc",
  "501192efa5eaf2683d211c7667bcefa7f3aadcf02edb970de2276792f26ae35a",
  "557df6e170b4ba3fec82e1916d7c9d7b7405e8e096585d7cac9a223dd45b8b53",
  "77e134761812844742921b9b7e50b3d4adaf1e0fd9d85ba3db384353431cb37b",
  "bc8069d022044a517a1d00271db04e934e1e5ec3eb53679baf2df7e38d54c092",
  "b20ae799da005e4c9e2c94d3da3c8ba4de4360f85ff78dd013fed0c0902515c8",
  "0ac9648b65799ca10e10ac2ffb1716733e253c535942a80c02350ef8d2e78255",
  "30e92c7ff59440eb040d869b4d6e011951a3ef9197e7284cf74c8d88ce650225",
  "7b4659d982ceb84e13c877063bf848741ac9835cf123f5b64d434c4af9e69202",
  "c64aa682258f9eb5221de25d0369b8d4ceea4e053708e5cbfa0f06969651247b",
  "69ec6aee9138ee93ce3260b0c4aa4a73c4a7c7fdbba69deba47324ce032369c3",
  "4cd62f444b5177535fb0f9976354e86978224d2d8d24848d4a06810c097aff62",
  "ba354a5c8f3c36ccda3d22f03f2c0194ac44bfc57a9408e1f24bccb6544e9db0",
  "a3eb202d592502980c18caca6041d950461ed05097779bbcfe5c9856af923cb4",
  "457f0c0b972b233660191347dac2155c457f8fc138c802d81fe45f2e4d42d76d",
  "28cbefcb4182d17e87efe6377df40e8184fe37c1de88983287a4e62606fefc28",
  "3e855715e513a4b01f6060663de8616de7c2b256d4e657b8c7d511404a75ac7b",
  "7d1a78377785b2b854b526c532b0ee4ba2182583b5f9664dc72df5ba5685f481",
  "79983eed4cb103ca096aa76e8c81ea89c76cfc0fb5b4c8a8c084d87cc5fbb9af",
  "6caa70dca43cb67596e5b40c28dc6793cad95b6c1c6acaa3010a742ada7df893",
  "c3a8bf4d0809bbe0a0d045f7707271b8901acdb2bf861ae0d9b8ad817c178f97",
  "c11b86f33dff6dba2a2e4d05f72de78db11274c7901e49d97194809b3eac0188",
  "21dd642ad84af49f0b0f5a44ef527f1990571eed3eae12a7718a2e9e5587f0f4",
  "f95c68bdc195ea49dc9f3ef03dec231def1f16f32ae8e14c5b29927f56f3a87b",
  "192cb26f625ee0a920827a58bc2198cf64a92ea353deeeacada6743a8f249e1a",
  "2ecb0b24613825f53bd64e3f681058f24178708a349020d74ac58f252291e66d",
  "e75c9112af04577791cec259cb84db0b8536eaeec02cab1eabea3c521f0df1c2",
  "e568c96df068a26f31131de7c87d4f3773d7ad49e515e13a1ee18f1c62ff2048",
  "cdf51436d4ed0e39fbc05b7441786187f96518c4114f428b8663f917341347ef",
  "2a0343a7d576d4b171dbf21307fdf97e45049fa64570a469c65e02aa91c56d3e",
  "4624ac0da2e0d84dd7fa53802e6ec438c7de6fe7fd1f4168dc79a63e4c53d6ea",
  "c34e57cbb541130b72c9c6bd7757fe5a92ee6e6900fc9d25d42796f1a674cff9",
  "ba34591465c41db272190cb85c6580dfe238dbda47094ff7085125dad9ddce30",
  "f135d4c004d331b2e7380bc0310a3822776edf2e37f7205eb52a06f83cfba389",
  "5216e73d572b07812377b5c77a0da40017eaeade2341451ca78e785f0c542524",
  "fff1e94beb5b2f9babdbf9cf2669d4fd0b1f59d3336c4241c3585ed99f729460",
  "45215ed2ef249608d83dbdbd0439bedd478aaacdfa8809237ca07468f31fc808",
  "537cc93ee1a8cbf9c870b62fa2a86bf31649226c1447dcd00759cf18707ab2de",
  "260b40d44b4b2b43064198357f973c98a2ffeeaaf0d9f1002ed47a906a6f9bbe",
  "d36e8268740ab7e1ca9717cbb8d4ba5faf1631f9df71393537294d1eccb9ade0",
  "73a64c069e4c83aaaec49b91a424d67ff978663be1b24d3d0b364e4d87055b27",
  "336fea013db55393fb4e18d0add81471059d49cabab4e8fbfd7d283306a5a217",
  "cd6740297eb1b6a002c42aeeb48a9ef3f1ec51cc4a05f9a0a63bed467f88aaae",
  "3287e9a8711da7c3d2fabd2266881c89710c29deed034b26d2c8041aa05ef639",
  "291df28642e84185e68b75b4a76e320dc8c95513bcb3861456a979c884be8f25",
  "051730b892ff42606868bb2caa28579edd927945d8cb77caea85d901727146cd",
  "0be98b675c23c6aa4b1e68fc265f30708be96728937af9ad31da8aa113651e82",
  "0fa5864c4cb13b815fa78e3e9e3ebaab307ed5de2fcece723b82a168e60fb5ee",
  "2d749bcd462e6c41fa88ddee75fad2f22a35e3a420895f5061cb9209b0bedc7b",
  "773f924fcb013a33ee7003839c85f1ab5007bde91af0cf621073068d0a8d3425",
  "9e18ab7a9fb764902666e030504a541c3bc6c90161e09ee19585514b960a6553"
]
const preHashedWhiteList = [
  "d286647acc04e6b84dbc060b7ba76a68f6b97e08fbd1e08e0bd3cd328bcbd2bd",
  "344a5ea6c525ca81aa17e12c0d704b9efdacd7796649b330515ea12c4f2431b2"
]

// const preWhitelist = []
// const leafNodes = preWhitelist.map(a => keccak256(a).toString('hex'));
// console.log(leafNodes)

export function isVip(addr) {
  return vipHashedWhiteList.includes(keccak256(addr).toString('hex')) ? true : false
}

export function isPre(addr) {
  return preHashedWhiteList.includes(keccak256(addr).toString('hex')) ? true : false
}

export function getProof(addr, isPre) {
  const merkleTree = new MerkleTree(isPre ? preHashedWhiteList : vipHashedWhiteList, keccak256, { sort: true })
  return merkleTree.getHexProof(keccak256(addr));
}

//create root
const merkleTree = new MerkleTree(vipHashedWhiteList, keccak256, { sort: true })
const hexroot = merkleTree.getHexRoot();
console.log(hexroot)



